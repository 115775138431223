<template>
  <div>
    <div class="container" v-if="page === 'main'">
      <div class=" head-text">
        <h3 class="color-primary text-center font-weight-bold">
          E-Learning
        </h3>
        <p class="color-primary text-big text-center font-weight-bold">
          กรุณาใส่กรอกข้อมูลสำหรับการ enroll
        </p>
        <h4 class="color-primary text-center font-weight-bold">
          {{ courseDetail.title ? `- ${courseDetail.title} -` : "" }}
        </h4>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="price-card color-primary">
            <p class="text-big text-center font-weight-bold">
              รายละเอียดการชำระเงิน
            </p>
            <div class="d-flex justify-content-between">
              <p>ราคาคอร์สเรียน</p>
              <p>
                {{
                  coursePrice.price
                    ? numberComma(coursePrice.price.toFixed(2))
                    : 0
                }}
                บาท
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <p>ส่วนลด</p>
              <p>0 บาท</p>
            </div>
            <div class="d-flex justify-content-between">
              <p>ภาษี (7%)</p>
              <p>
                {{
                  coursePrice.price
                    ? numberComma((coursePrice.price * 0.07).toFixed(2))
                    : 0
                }}
                บาท
              </p>
            </div>
            <hr />
            <div class="d-flex justify-content-between font-weight-bold">
              <p>รวม</p>
              <p>
                {{
                  coursePrice.price
                    ? numberComma(
                        (coursePrice.price + coursePrice.price * 0.07).toFixed(
                          2
                        )
                      )
                    : 0
                }}
                บาท
              </p>
            </div>
          </div>
          <!-- <div class="info-form">
            <label class="color-primary">รหัสส่วนลด</label>
            <input
              type="text"
              class="w-100 text-big"
              placeholder="กรุณาใสรหัสส่วนลด"
            />
          </div> -->
        </div>
        <div class="col-12 col-md-6">
          <div class="info-form">
            <form @submit.prevent="createPayment">
              <label class="color-primary">ชื่อ</label>
              <input
                type="text"
                v-model="firstname"
                class="w-100 text-big"
                placeholder="ชื่อ"
                required
              />
              <label class="color-primary">นามสกุล</label>
              <input
                type="text"
                v-model="lastname"
                class="w-100 text-big"
                placeholder="นามสกุล"
                required
              />
              <label class="color-primary">ที่อยู่</label>
              <textarea
                v-model="address"
                rows="5"
                class="w-100 text-big"
                placeholder="กรุณาใส่ที่อยู่ของท่าน"
                required
              ></textarea>
              <label class="color-primary">ตำบล/แขวง</label>
              <input
                type="text"
                v-model="subDistrict"
                class="w-100 text-big"
                placeholder="ตำบล"
                required
              />
              <label class="color-primary">อำเภอ/เขต</label>
              <input
                type="text"
                v-model="district"
                class="w-100 text-big"
                placeholder="อำเภอ"
                required
              />
              <label class="color-primary">จังหวัด</label>
              <div class="position-relative">
                <select class="d-block w-100" v-model="province" required>
                  <option
                    v-for="province in provinceList"
                    :key="province"
                    :value="province"
                    style="color: black;"
                    >{{ province }}</option
                  >
                </select>
                <img
                  class="dropdown-icon"
                  src="../assets/images/dropdown-icon.svg"
                  alt=""
                />
              </div>
              <label class="color-primary">รหัสไปรษณีย์</label>
              <input
                type="number"
                v-model="postcode"
                class="w-100 text-big"
                placeholder="รหัสไปรษณีย์"
                required
              />
              <label class="color-primary">เบอร์โทรศัพท์</label>
              <input
                type="number"
                v-model="phone"
                class="w-100 text-big"
                placeholder="กรุณาใส่เบอร์โทรศัพท์"
                required
              />
              <label class="mb-0 color-primary">เลขบัตรประชาชน</label>
              <p class="mb-2 color-primary">
                *เพื่อสิทธิประโยชน์ในการออกใบประกาศณียบัตร
              </p>
              <input
                type="number"
                v-model="idCard"
                class="w-100 text-big"
                placeholder="เลขบัตรประชาชน"
                required
              />
              <button class="w-100 mt-2"><p class="text-big">ยืนยัน</p></button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="center-page" v-if="page === 'redirect'">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div>
          <div class="pay-card mx-auto">
            <img src="../assets/images/paysolution.png" alt="" />
          </div>
          <h4 class="color-primary text-center">
            เรากำลังนำท่านไปสู่ Paysolution
          </h4>
        </div>
      </div>
    </div>
    <div class="center-page" v-if="page === 'success'">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div>
          <img class="w-100 mb-3" src="../assets/images/success.png" alt="" />
          <h4 class="color-primary text-center">
            คุณ Enroll สำเร็จแล้ว
          </h4>
          <p class="color-primary text-center">
            ขอบคุณสำหรับความสนใจในคอร์สของเรา
          </p>
          <button class="secondary" @click="goToMyCourse">
            <p class="text-big">ไปยังคอร์สเรียนของฉัน</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numberComma from "../util/numberComma";
import { formPaysolution } from "../util/paysolution";
import axios from "../api/faAPI";
import baseUrl from "../util/backend";
import provinceList from "../assets/mock/thaiprovince.json";
export default {
  data() {
    return {
      isLoading: false,
      courseDetail: {},
      coursePrice: {},
      provinceList: provinceList,
      page: "main",
      firstname: "",
      lastname: "",
      address: "",
      district: "",
      subDistrict: "",
      province: "",
      postcode: "",
      phone: "",
      idCard: "",
    };
  },
  computed: {
    userDetail() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    numberComma,
    async createPayment() {
      if (this.coursePrice.price > 0) {
        const date = new Date().getTime();
        const refNo = Math.floor(date / 10);
        try {
          const res = await axios.post(`${baseUrl}/createPayment`, {
            user_id: this.userDetail.user_id,
            course_price_id: this.coursePrice.course_price_id,
            refno: refNo,
            promotion_id: null,
            first_name: this.firstname,
            last_name: this.lastname,
            address: this.address,
            district: this.district,
            sub_district: this.subDistrict,
            province: this.province,
            postcode: this.postcode,
            id_card: this.idCard,
            phone: this.phone,
          });
          console.log(res.data.status);
          if (res.data.status === "success") {
            this.openPaySolution(refNo);
          }
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      } else {
        try {
          const res = await axios.post(`${baseUrl}/createFreePayment`, {
            first_name: this.firstname,
            last_name: this.lastname,
            user_id: this.userDetail.user_id,
            course_price_id: this.coursePrice.course_price_id,
            promotion_id: null,
            address: this.address,
            district: this.district,
            province: this.province,
            sub_district: this.subDistrict,
            postcode: this.postcode,
            id_card: this.idCard,
            phone: this.phone,
          });
          console.log(res.data);
          if (res.data.status === "success") {
            this.page = "success";
            setTimeout(() => {
              this.$router.replace({ name: "MyCourse" });
            }, 3000);
          }
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
    },
    openPaySolution(refNo) {
      formPaysolution({
        id: this.$route.params.courseId,
        refNo: refNo,
        detail: this.courseDetail.title,
        total: 1,
        baseUrl: baseUrl,
        email: this.$store.getters.getUser.email,
      });
    },
    goToMyCourse() {
      // this.$router.replace({ name: "MyCourse" });
    },
    checkSuccess() {
      if (this.$route.query.status === "success") {
        this.page = "success";
        setTimeout(() => {
          this.$router.replace({ name: "MyCourse" });
        }, 3000);
      }
    },
    async loadCourseDetail() {
      this.isLoading = true;
      try {
        const res = await axios.get(`${baseUrl}/getCourse`, {
          params: { course_id: this.$route.params.courseId },
        });
        this.courseDetail = res.data.courseDetail.course.course;
        this.coursePrice = res.data.courseDetail.price;
      } catch (err) {
        if (err.response) {
          alert(err.response.data);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },
    setUser() {
      this.firstname = this.userDetail.first_name;
      this.lastname = this.userDetail.last_name;
      this.idCard = this.userDetail.id_card;
      this.address = this.userDetail.address;
      this.district = this.userDetail.district;
      this.subDistrict = this.userDetail.sub_district;
      this.province = this.userDetail.province;
      this.postcode = this.userDetail.postcode;
      this.id_card = this.userDetail.id_card;
      this.phone = this.userDetail.phone;
    },
  },
  created() {
    this.checkSuccess();
    this.setUser();
    this.loadCourseDetail();
  },
};
</script>

<style scoped>
.head-text {
  margin-bottom: 40px;
}

.price-card {
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 370px;
  padding: 15px 20px;
  margin: 0 auto 20px auto;
}

.price-card hr {
  border-top: 1px solid #0430ad;
}

.info-form {
  max-width: 370px;
  margin: 0 auto;
}

.info-form input,
.info-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  outline: none;
  border: 1px solid #0430ad;
}

.info-form button {
  color: #fff;
  background-color: #07c5f8;
  padding: 5px;
}

.center-page {
  height: 500px;
}

.pay-card {
  width: fit-content;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

button.secondary {
  display: block;
  color: #fff;
  background-color: #07c5f8;
  padding: 10px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

select {
  text-align: center;
  border: 1px solid #0430ad;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background-color: transparent;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: -1;
}
</style>
